import AnalyticsIcon from '@mui/icons-material/Analytics';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import InventoryIcon from '@mui/icons-material/Inventory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import type { AbilitySubjectsStr } from 'core/permissions/defineAbilityFor';

export interface NavConfigItem {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: string;
  permission?: AbilitySubjectsStr;
}

export const navConfig: NavConfigItem[] = [
  {
    title: 'Аналитика',
    path: '/analytics',
    icon: <AnalyticsIcon />,
    permission: 'Analytics',
  },
  {
    title: 'Календарь',
    path: '/calendar',
    icon: <CalendarMonthIcon />,
  },
  {
    title: 'Заявки',
    path: '/requests',
    icon: <WorkHistoryIcon />,
  },
  {
    title: 'Заказы',
    path: '/orders',
    icon: <ShoppingCartIcon />,
  },
  {
    title: 'Архив',
    path: '/archive',
    icon: <InventoryIcon />,
  },
  {
    title: 'Сотрудники',
    path: '/users',
    icon: <PeopleAltIcon />,
    permission: 'Users',
  },
  {
    title: 'Клиенты',
    path: '/clients',
    icon: <Diversity3Icon />,
    permission: 'Clients',
  },
  {
    title: 'Товары',
    path: '/products',
    icon: <WarehouseIcon />,
    permission: 'Products',
  },
  {
    title: 'Выплаты',
    path: '/payouts',
    icon: <MonetizationOnIcon />,
    permission: 'Payouts',
  },
];
