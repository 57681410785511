import { isNil } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import StoreContext from 'stores/context/StoreContext';
import type { AbilitySubjects } from 'core/permissions/defineAbilityFor';

/**
 *
 * @param root0
 * @param root0.children
 */
const RouteAbilityGuard = observer(
  ({
    children,
    ability,
    fallback = '/',
  }: {
    children: JSX.Element;
    ability: AbilitySubjects;
    fallback?: string;
  }): JSX.Element => {
    const location = useLocation();
    const { authStore, userStore } = useContext(StoreContext);

    if (!authStore.isAuthenticated.get()) {
      throw new Error('Необходимо авторизоваться');
    }

    if (
      !isNil(userStore.userData) &&
      !(userStore.ability?.can('read', ability) ?? false)
    ) {
      return <Navigate to={fallback} state={{ from: location }} replace />;
    }

    return children;
  }
);

export default RouteAbilityGuard;
